import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bcc21a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "path1"
}
const _hoisted_2 = {
  key: 1,
  class: "path2"
}
const _hoisted_3 = {
  key: 2,
  class: "path3"
}
const _hoisted_4 = {
  key: 3,
  class: "path4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("i", {
    class: _normalizeClass(["app-icon", `${_ctx.name}`]),
    style: _normalizeStyle(_ctx.iconStyle)
  }, [
    (_ctx.path)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.path)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.path3)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.path4)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4))
      : _createCommentVNode("", true)
  ], 6))
}