
import { defineComponent } from 'vue';
import AppText from '@/ui/app-text/AppText.vue';
import AppIcon from '@/ui/app-icon/AppIcon.vue';

export default defineComponent({
  name: 'AppModal',
  components: {
    AppText,
    AppIcon
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    isShow: {
      type: Boolean,
      default: false,
      required: true
    },
    isShowCross: {
      type: Boolean,
      default: true
    },
    modalPosition: {
      type: String,
      default: 'center'
    },
    mayCloseOnBackground: {
      type: Boolean,
      default: true
    },
    isShowAnimation: {
      type: Boolean,
      default: true
    },
    maxWidth: {
      type: String
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
      animate: false,
      animateTimeout: -1,
      show: this.isShow
    };
  },
  methods: {
    close () {
      this.$emit('close');
    },
    closeOnBackground () {
      if (this.mayCloseOnBackground) {
        this.$emit('close');
      } else if (this.isShowAnimation) this.animateClick();
    },
    stop (e: Event) {
      e.stopPropagation();
    },
    animateClick () {
      this.animate = false;

      this.$nextTick(() => {
        this.animate = true;
        window.clearTimeout(this.animateTimeout);
        this.animateTimeout = window.setTimeout(() => (this.animate = false), 150);
      });
    },
    changeMaxWidth () {
      let dialog = document.getElementsByClassName('sw-modal__dialog');
      if (dialog[0]) dialog[0].setAttribute('style', `max-width: ${this.maxWidth}`);
    }
  },
  watch: {
    isShow (value) {
      let body = document.body;
      let html = document.getElementsByTagName('html');
      let innerWidth = window.innerWidth;
      let clientWidth = document.body.clientWidth;

      if (value) {
        html[0].classList.add('hide-scroll');
        body.style.marginRight = `${innerWidth - clientWidth}px`;
      } else {
        html[0].classList.remove('hide-scroll');
        body.style.marginRight = '0';
      }

      new Promise<void>(resolve => {
        setTimeout(() => {
          this.show = value;
          resolve();
        }, 290);
      }).then(() => {
        if (this.maxWidth && value) {
          this.changeMaxWidth();
        }
      });
    },
    maxWidth () {
      this.changeMaxWidth();
    }
  },
  computed: {
    dynamicClass () {
      return [
        {
          'modal-top': this.modalPosition === 'top',
          'modal-center': this.modalPosition === 'center',
          'animate': this.animate,
          'open-modal': this.isShow,
          'modal-closed': !this.isShow,
          [`app-modal__type-${this.type}`]: !!this.type
        }
      ];
    }
  }
});
