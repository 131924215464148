import {
  IAd,
  ICategoriesStreams,
  ICountriesStreams,
  IFilters, IIntegration,
  ISearchShowcase,
  IStreamerResponse
} from '@/store/modules/search-showcase/showcase.types'
import IObject from '@/types/IObject';
import $HttpClient from '@/utils/http-clients/http-client';
import { ShowcaseCategoriesModel } from '@/models/ShowcaseCategoriesModel';
import { IStreamer } from '@/types/IStreamer';
import { ShowCaseIntegrations } from '@/models/ShowCaseIntegrations'

const state = (): ISearchShowcase => ({
  COUNTRIES: [] as Array<ICountriesStreams>,
  CATEGORIES: [] as Array<ICategoriesStreams>,
  ADS: [] as Array<IAd>,
  ACTIVE_ADS: [] as Array<IAd>,
  STREAMERS: [] as Array<IStreamer>,
  ACTIVE_CATEGORIES: [] as Array<ICategoriesStreams>,
  INTEGRATIONS: [] as Array<IIntegration>,
  ACTIVE_INTEGRATIONS: [] as Array<IIntegration>,
  ACTIVE_COUNTRY: {
    id: 0,
    name: 'Нет'
  } as ICountriesStreams,
  SEARCH_STREAMERS: [] as Array<IStreamer>,
  FILTERS: {
    offset: 0,
    limit: 10,
    sex: '',
    category_ids: [],
    sorting: 'budged',
    sorting_direction: 'desc',
    country_id: 0,
    age_group: 0,
    username: ''
  } as IFilters,
  TOTAL: 0
});

const mutations = {
  SAVE_STREAMERS (state: ISearchShowcase, payload: IStreamer[]): void {
    state.STREAMERS = payload;
  },
  SAVE_CATEGORIES (state: ISearchShowcase, payload: ICategoriesStreams[]): void {
    state.CATEGORIES = payload.map(item => new ShowcaseCategoriesModel(item));
  },
  SAVE_COUNTRIES (state: ISearchShowcase, payload: ICountriesStreams[]): void {
    state.COUNTRIES = payload;
    state.COUNTRIES.push(
      {
        id: 0,
        name: 'Нет'
      }
    );
  },
  SAVE_ACTIVE_COUNTRY (state: ISearchShowcase, payload: ICountriesStreams): void {
    state.ACTIVE_COUNTRY = payload;
  },
  SAVE_ACTIVE_CATEGORIES (state: ISearchShowcase, payload: ICategoriesStreams): void {
    const result = state.ACTIVE_CATEGORIES.findIndex(item => item.id === payload.id);

    result === -1
      ? state.ACTIVE_CATEGORIES.push(payload)
      : state.ACTIVE_CATEGORIES.splice(result, 1);
  },
  SAVE_INTEGRATION_BUTTONS (state: ISearchShowcase): void {
    // Mark parent integration button if necessary
    state.INTEGRATIONS.forEach(function (integration: IIntegration) {
      let unselected = 0
      integration.ads.forEach(function (ad: IAd) {
        if (!ad.checked) {
          unselected++
        }
      })
      if (unselected === integration.ads.length) {
        integration.checked = false
      } else {
        integration.checked = true
      }
    })
  },
  SAVE_CHANGE_INTEGRATION (state: ISearchShowcase, payload: IIntegration): void {
    payload.checked = !payload.checked
    payload.ads.forEach(function (ad: IAd): void {
      ad.checked = payload.checked
    })
  },
  CLEAR_ACTIVE_CATEGORIES (state: ISearchShowcase): void {
    state.CATEGORIES.forEach(item => item.checked = false);
    state.ACTIVE_CATEGORIES = [];
  },
  SAVE_SEARCH_STREAMERS (state: ISearchShowcase, payload: IStreamer []): void {
    state.SEARCH_STREAMERS = payload;
  },
  SAVE_TOTAL (state: ISearchShowcase, payload: number): void {
    state.TOTAL = payload;
  },
  SAVE_ADS (state: ISearchShowcase, payload: IIntegration[]): void {
    payload.forEach(function (integration: IIntegration) {
      integration.ads.forEach(function (ad: IAd) {
        ad.checked = false
      })
    })
    state.INTEGRATIONS = payload
  },
  CHANGE_AD (state: ISearchShowcase, payload: IAd): void {
    state.INTEGRATIONS.forEach(function (integration: IIntegration) {
      integration.ads.forEach(function (ad: IAd) {
        if (ad.id === payload.id) {
          ad.checked = !ad.checked
        }
      })
    })
  },
  CLEAR_SELECTED_ADS_AND_INTEGRATIONS (state: ISearchShowcase): void {
    state.INTEGRATIONS.forEach(item => item.checked = false);
    state.INTEGRATIONS.forEach(item => item.ads.forEach(ad => ad.checked = false));
    state.ACTIVE_ADS = [];
    state.ACTIVE_INTEGRATIONS = [];
  },
  SAVE_ON_ACTION_ACTIVE_CATEGORIES (state: ISearchShowcase, payload: ICategoriesStreams[]): void {
    state.ACTIVE_CATEGORIES = payload;
  },
  SET_ACTIVE_ADS (state: ISearchShowcase, payload: string[]): void {
    state.INTEGRATIONS.forEach(function (integration: IIntegration) {
      integration.ads.forEach(function (ad: IAd) {
        if (payload.includes(ad.id.toString())) {
          ad.checked = true
        }
      })
    })
  },
  SAVE_ON_ACTION_ACTIVE_ADS (state: ISearchShowcase, payload: IAds[]): void {
    state.ACTIVE_ADS = payload;
  },
};

const actions = {
  GET_STREAMERS ({ commit }: IObject, payload: IObject): Promise<IStreamerResponse> {
    return $HttpClient.get<Array<IStreamer>>('/streamers', { params: payload })
      .then((response: IObject) => {
        commit('SAVE_STREAMERS', response.data.streamers);
        commit('SAVE_TOTAL', response.data.total_count);

        return {
          ...response.data,
          ...response.max_page
        };
      })
      .catch(e => {
        console.log(e);
      });
  },
  GET_CATEGORIES ({ commit }: IObject, payload: IObject): Promise<Array<IStreamer>> {
    return $HttpClient.get<Array<IStreamer>>('/categories', payload)
      .then((response: IObject) => {
        commit('SAVE_CATEGORIES', response.data);
        return response.data;
      })
      .catch(e => {
        console.log(e);
      });
  },
  GET_COUNTRIES ({ commit }: IObject, payload: IObject): Promise<Array<IStreamer>> {
    return $HttpClient.get<Array<IStreamer>>('/countries', payload)
      .then((response: IObject) => {
        commit('SAVE_COUNTRIES', response.data);
        return response.data;
      })
      .catch(e => {
        console.log(e);
      });
  },
  GET_STREAMER_BY_USERNAME ({ commit }: IObject, payload: string): Promise<Array<IStreamer>> {
    return $HttpClient.get<Array<IStreamer>>(`/streamers/username?username=${payload}`)
      .then((response: IObject) => {
        commit('SAVE_SEARCH_STREAMERS', response.data.streamers);
        return response.data.streamers;
      })
      .catch(e => {
        console.log(e);
      });
  },
  GET_ADS ({ commit }: IObject, payload: IObject): Promise<Array<IAd>> {
    return $HttpClient.get<Array<IStreamer>>('/ads', payload)
      .then((response: IObject) => {
        commit('SAVE_ADS', response.data);
        return response.data;
      })
      .catch(e => {
        console.log(e);
      });
  },
  SET_ACTIVE_COUNTRY ({ commit }: IObject, payload: ICountriesStreams): void {
    commit('SAVE_ACTIVE_COUNTRY', payload);
  },
  RESET_ACTIVE_CATEGORIES ({ commit }: IObject): void {
    commit('CLEAR_ACTIVE_CATEGORIES');
  },
  RESET_SELECTED_ADS_AND_INTEGRATIONS ({ commit }: IObject): void {
    commit('CLEAR_SELECTED_ADS_AND_INTEGRATIONS');
  },
  SET_ACTIVE_CATEGORIES ({ commit }: IObject, payload: ICategoriesStreams): void {
    commit('SAVE_ACTIVE_CATEGORIES', payload);
  },
  CHECK_INTEGRATION_BUTTONS ({ commit }: IObject, payload: ICategoriesStreams): void {
    commit('SAVE_INTEGRATION_BUTTONS', payload);
  },
  CHANGE_INTEGRATION ({ commit }: IObject, payload: IIntegration): void {
    commit('SAVE_CHANGE_INTEGRATION', payload);
  },
  CHANGE_AD ({ commit }: IObject, payload: IAd): void {
    commit('CHANGE_AD', payload);
  },
  SET_ACTIVE_ADS ({ commit }: IObject, payload: IAd): void {
    commit('SET_ACTIVE_ADS', payload);
  },
};

const getters = {
  COUNTRIES (state: ISearchShowcase): ICountriesStreams[] {
    return state.COUNTRIES;
  },
  CATEGORIES (state: ISearchShowcase): ICategoriesStreams[] {
    return state.CATEGORIES;
  },
  streamers (state: ISearchShowcase): IStreamer[] {
    return state.STREAMERS;
  },
  ACTIVE_COUNTRY (state: ISearchShowcase): ICountriesStreams {
    return state.ACTIVE_COUNTRY;
  },
  ACTIVE_CATEGORIES (state: ISearchShowcase): Array<ICategoriesStreams> {
    return [...state.ACTIVE_CATEGORIES];
  },
  ACTIVE_ADS (state: ISearchShowcase): Array<IAd> {
    return [...state.ACTIVE_ADS];
  },
  STREAMERS_BY_USERNAME (state: ISearchShowcase): IStreamer[] {
    return state.SEARCH_STREAMERS;
  },
  TOTAL (state: ISearchShowcase): number {
    return state.TOTAL;
  },
  ADS (state: ISearchShowcase): IAd[] {
    return state.ADS;
  },
  INTEGRATIONS (state: ISearchShowcase): Array<IIntegration> {
    return state.INTEGRATIONS
  }
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true
};
