import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeletor = _resolveComponent("Skeletor")!
  const _directive_current_text = _resolveDirective("current-text")!

  return (!_ctx.isFetch)
    ? _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
        key: 0,
        class: _normalizeClass([`app-text__${_ctx.tag} ${_ctx.status}`, "app-text"]),
        style: _normalizeStyle(_ctx.textStyle)
      }, null, 8, ["class", "style"])), [
        [_directive_current_text, { text: _ctx.text, isHtml: _ctx.isHtml }]
      ])
    : (_ctx.isFetch)
      ? (_openBlock(), _createBlock(_component_Skeletor, {
          key: 1,
          width: "100px"
        }))
      : _createCommentVNode("", true)
}