
import { defineComponent, DirectiveBinding, PropType } from 'vue';
import { isValidStringProp } from '@/utils/global/validateProp';
import * as H from '@/ui/app-text/AppText.types';
import IObject from '@/types/IObject';

export default defineComponent({
  name: 'AppText',
  props: {
    text: {
      type: [ String, Number ],
      default: '',
      required: true
    },
    tag: {
      type: String as PropType<H.TTagText>,
      default: 'span',
      validator: (value: string): boolean => isValidStringProp(value, H.tagTextEnum)
    },
    status: {
      type: String as PropType<H.TStatusText>,
      default: 'default',
      validator: (value: string): boolean => isValidStringProp(value, H.statusTextEnum)
    },
    fontSize: {
      type: [ Number, String ],
      validator: (value: string | number): boolean => !Number.isNaN(+value)
    },
    lineHeight: {
      type: [ Number, String ],
      validator: (value: string | number): boolean => !Number.isNaN(+value)
    },
    fontWeight: {
      type: [ String, Number ],
      validator: (value: string | number): boolean => !Number.isNaN(+value)
    },
    isFetch: {
      type: Boolean,
      default: false
    },
    isHtml: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    textStyle (): IObject {
      return {
        'font-size': `${this.fontSize}px`,
        'line-height': `${this.lineHeight}px`,
        'font-weight': this.fontWeight
      };
    }
  },
  directives: {
    currentText: {
      beforeMount (el: HTMLElement, binding: DirectiveBinding): void {
        const innerTarget = binding.value.isHtml
          ? 'innerHTML'
          : 'innerText';

        el[innerTarget] = binding.value.text;
      },
      updated (el: HTMLElement, binding: DirectiveBinding): void {
        const innerTarget = binding.value.isHtml
          ? 'innerHTML'
          : 'innerText';

        el[innerTarget] = binding.value.text;
      }
    }
  }
});
