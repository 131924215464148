import IObject from '@/types/IObject';
import $HttpClient from '@/utils/http-clients/http-client';
import EX_$Observer from '@/classes/base/observer';
import {
  IBank,
  ICompany,
  IProfile,
  IProfileInfo,
  IProfileInfoResponse
} from '@/store/modules/profile/profile.types';

const state = (): IProfile => ({
  info: {
      is_verified: true
  } as IProfileInfo,
  company: {} as ICompany,
  bank: {} as IBank
});

const mutations = {
  SAVE_PROFILE (state: IProfile, payload: IProfileInfo): void {
    state.info = payload;
  },
  SAVE_COMPANY (state: IProfile, payload: ICompany): void {
    state.company = payload;
  }
};

const actions = {
  GET_INFO ({ commit }: IObject): Promise<IProfileInfoResponse> {
    return $HttpClient.get<IProfileInfoResponse>(`/profile`)
      .then((response: IObject) => {
        commit('SAVE_PROFILE', response.data);

        return response.data;
      })
      .catch(e => {
        console.log(e);
      });
  },
  UPDATE_NAME ({ commit }: IObject, payload: IObject): Promise<IProfileInfoResponse> {
    return $HttpClient.post<IProfileInfoResponse>(`/profile/name`, {
      body: payload
    })
      .then((response: IObject) => {
        EX_$Observer.context.$notification({
          text: 'Имя изменено'
        })
        return response.data;
      })
  },
  UPDATE_PASSWORD ({ commit }: IObject, payload: IObject): Promise<IProfileInfoResponse> {
    return $HttpClient.post<IProfileInfoResponse>(`/profile/password`, {
      body: payload
    })
      .then((response: IObject) => {
        EX_$Observer.context.$notification({
          text: 'Пароль изменен'
        })
        return response.success;
      })
  },
  UPDATE_EMAIL ({ commit }: IObject, payload: IObject): Promise<IProfileInfoResponse> {
    return $HttpClient.post<IProfileInfoResponse>(`/profile/request_email_change`, {
      body: payload
    })
      .then((response: IObject) => {
        EX_$Observer.context.$notification({
          text: 'На ваш email был отправлен код'
        })
        return response.success;
      })
  },
  CHANGE_EMAIL ({ commit }: IObject, payload: IObject): Promise<IProfileInfoResponse> {
    return $HttpClient.post<IProfileInfoResponse>(`/profile/email`, {
      body: payload
    })
      .then((response: IObject) => {
        EX_$Observer.context.$notification({
          text: 'Email изменен'
        })
        return response.success;
      })
  },
  GET_COMPANY ({ commit }: IObject): Promise<IProfileInfoResponse> {
    return $HttpClient.get<IProfileInfoResponse>(`/profile/company`)
      .then((response: IObject) => {
        return response.data;
      })
      .catch(e => {
        console.log(e);
      });
  },
  UPD_COMPANY_INFO ({ commit }: IObject, payload: IObject): Promise<IProfileInfoResponse> {
    return $HttpClient.post<IProfileInfoResponse>(`/profile/company`, {
      body: payload
    })
      .then((response: IObject) => {
        EX_$Observer.context.$notification({
          text: 'Данные изменены'
        })
        return response.data;
      })
  },
  GET_BANK_INFO ({ commit }: IObject): Promise<IProfileInfoResponse> {
    return $HttpClient.get<IProfileInfoResponse>(`/profile/bank`)
      .then((response: IObject) => {
        return response.data;
      })
      .catch(e => {
        console.log(e);
      });
  },
  UPD_BANK_INFO ({ commit }: IObject, payload: IObject): Promise<IProfileInfoResponse> {
    return $HttpClient.post<IProfileInfoResponse>(`/profile/bank`, {
      body: payload
    })
      .then((response: IObject) => {
        EX_$Observer.context.$notification({
          text: 'Данные изменены'
        })
        return response.data;
      })
  }
};

const getters = {
  INFO (state: IProfile): IProfileInfo {
    return state.info;
  },
  IS_VERIFIED (state: IProfile): boolean {
    return state.info.is_verified;
  },
  COMPANY (state: IProfile): ICompany {
    return state.company;
  }
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true
};
