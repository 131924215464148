import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-modal__dialog-wrap" }
const _hoisted_2 = { class: "app-modal__heading" }
const _hoisted_3 = { class: "app-modal-modal__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_text = _resolveComponent("app-text")!
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: _normalizeClass(["app-modal", _ctx.dynamicClass]),
        onMousedown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeOnBackground && _ctx.closeOnBackground(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "app-modal__dialog",
            onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.stop && _ctx.stop(...args)))
          }, [
            _createElementVNode("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "heading", {}, () => [
                _createVNode(_component_app_text, {
                  text: _ctx.heading,
                  class: "app-modal__default-header",
                  "font-size": "18"
                }, null, 8, ["text"])
              ]),
              (_ctx.isShowCross)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "app-modal__close",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                  }, [
                    _createVNode(_component_app_icon, {
                      name: "icon-cross",
                      size: "12"
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ], 32)
        ])
      ], 34))
    : _createCommentVNode("", true)
}