import IObject from '@/types/IObject';
import $HttpClient from '@/utils/http-clients/http-client';
import { IStreamer } from '@/types/IStreamer';
import { IOrders, IOrder } from '@/store/modules/order-sheet/orderSheet.types';
import { OrderSheetModel } from '@/models/OrderSheetModel';

const state = (): IOrders => ({
  orders: [] as Array<IOrder>,
  total_count: 0,
  max_page: 0
});

const mutations = {
  SAVE_ORDERS (state: IOrders, payload: Array<IOrder>): void {
    state.orders = payload.map(item => new OrderSheetModel(item));
  },
  SAVE_TOTAL_COUNT (state: IOrders, payload: number): void {
    state.total_count = payload;
  },
  SAVE_MAX_PAGE (state: IOrders, payload: number): void {
    state.max_page = payload;
  }
};

const actions = {
  GET_ORDERS ({ commit }: IObject, payload: IObject): Promise<IOrders> {
    return $HttpClient.get<IStreamer>(`/orders`, { params: payload })
      .then((response: IObject) => {
        commit('SAVE_ORDERS', response.data.orders);
        commit('SAVE_TOTAL_COUNT', response.data.total_count);
        commit('SAVE_MAX_PAGE', response.data.max_page);

        return response.data;
      })
      .catch(e => {
        console.log(e);
      });
  }
};

const getters = {
  ORDERS (state: IOrders): Array<IOrder> {
    return state.orders;
  },
  TOTAL_COUNT (state: IOrders): number {
    return state.total_count;
  },
  MAX_PAGE (state: IOrders): number {
    return state.max_page;
  }
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true
};
