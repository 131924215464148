import { App } from 'vue'
import { useToast } from 'vue-toastification'
import AppToast from '@/ui/app-toast/AppToast.vue'

export interface IToastOptions {
  title?: string
  text?: string
  href?: string
  type?: string
}

export default {
  install: (app: App<Element>): void => {
    let notificationCounter = 0

    app.config.globalProperties.$notification = ( options: IToastOptions ): void => {
      notificationCounter++

      if (notificationCounter === 1) {  // Зашита от двойных уведомлений
        setTimeout(() => {
          const toast = useToast()

          switch (options.type) {
            case 'error':
              toast.error({
                component: AppToast,
                timeout: 3000,
                props: {
                  text: options.text,
                  title: options.title,
                  href: options.href,
                  type: 'error'
                }
              })
              break;

            default:
              toast({
                component: AppToast,
                timeout: 3000,
                props: {
                  text: options.text,
                  title: options.title,
                  href: options.href,
                  type: 'success'
                }
              })
          }

          notificationCounter = 0
        }, 200)
      }
    }
  }
}
