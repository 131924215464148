import IObject from '@/types/IObject';
import { IStreamerCart } from '@/store/modules/cart/cart.types';

export class OrderSheetModel {
  public campaign_id = 0
  public streamers = [] as Array<IStreamerCart>
  public total_price = 0
  public isOpen = false
  public views_forecast = 0
  public campaign_name = ''
  public cpv = 0
  public broadcast_forecast = 0
  public unique_viewers_forecast = 0
  public started_at = ''

  constructor (obj: IObject) {
    Object.keys(this)
      .forEach((key) => {
        const has = Object.prototype.hasOwnProperty.call(obj, key);
        // @ts-ignore
        this[key] = has ? obj[key] : this[key];
      });
  }
}
