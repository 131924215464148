import IObject from '@/types/IObject';
import $HttpClient from '@/utils/http-clients/http-client';
import {
  IAddUpdCart,
  ICart,
  ICartServices,
  IDetails,
  IPostCartService,
  IStreamerCart,
  ITotalCart
} from '@/store/modules/cart/cart.types';
import { StreamerCartModel } from '@/models/StreamerCartModel';
import * as moment from "moment";
import EX_$Observer from '@/classes/base/observer';

const state = (): ICart => ({
  _streamers: [] as Array<IStreamerCart>,
  _total: {} as ITotalCart,
  _meleeCart: { services: []} as ICartServices,
  _campaign_name: '',
  _details: {} as IDetails
});

const mutations = {
  SAVE_STREAMERS (state: ICart, payload: Array<IStreamerCart>): void {
    state._streamers = payload.map(item => new StreamerCartModel(item));
  },
  SAVE_TOTAL (state: ICart, payload: ITotalCart): void {
    state._total = payload;
  },
  SAVE_MELEE_CART (state: ICart, payload: Array<IStreamerCart>): void {
    state._meleeCart.services = [];

    payload.forEach(item => {
      if (item.services.branding.quantity > 0) {
        state._meleeCart.services.push({
          id: item.services.branding.streamer_service_id,
          quantity: item.services.branding.quantity,
        });
      }

      if (item.services.voice?.quantity > 0) {
        state._meleeCart.services.push({
          id: item.services.voice.streamer_service_id,
          quantity: item.services.voice.quantity,
        });
      }

      if (item.services.sponsor?.quantity > 0) {
        state._meleeCart.services.push({
          id: item.services.sponsor.streamer_service_id,
          quantity: item.services.sponsor.quantity,
        });
      }

      if (item.services.product?.quantity > 0) {
        state._meleeCart.services.push({
          id: item.services.product.streamer_service_id,
          quantity: item.services.product.quantity,
        });
      }
    });
  },
  SAVE_CAMPAIGN_NAME (state: ICart, payload: string): void {
    state._campaign_name = payload;
  },
  SAVE_DETAILS (state: ICart, payload: IDetails): void {
    state._details = payload;
  }
};

const actions = {
  ADD_OR_UPD_CART ({ commit }: IObject, payload: IObject): Promise<IAddUpdCart> {
    return $HttpClient.post<IAddUpdCart>(`/cart/addStreamer`, {
      body: payload
    })
      .then((response: IObject) => {
        return response.data
      })
  },
  GET_CART ({ commit }: IObject, payload: IObject): Promise<ICart> {
    return $HttpClient.get<ICart>(`/cart`)
      .then((response: IObject) => {
        commit('SAVE_STREAMERS', response.data.streamers);
        commit('SAVE_MELEE_CART', response.data.streamers);
        commit('SAVE_TOTAL', response.data.total);
        commit('SAVE_DETAILS', response.data.details);
        commit('SAVE_CAMPAIGN_NAME', response.data.campaign_name);

        return response.data;
      })
  },
  REMOVE_STREAMER ({ commit }: IObject, payload: number): Promise<ICart> {
    return $HttpClient.post<ICart>(`/cart/removeStreamer`, {
      body: payload
    })
      .then((response: IObject) => {
        return response.data;
      })
  },
  PLACE_ORDER ({ commit }: IObject, payload: FormData): Promise<ICart> {
    return $HttpClient.post<ICart>(`/cart/order`, {
        body: payload
      })
      .then((response: IObject) => {
        return response.data;
      })
  },
  EMPTY_CART (): Promise<ICart> {
    return $HttpClient.post<ICart>(`/cart/empty`)
      .then((response: IObject) => {
        return response.data;
      })
  },
  GET_DETAILS ({ commit }: IObject): Promise<ICart> {
    return $HttpClient.get<ICart>(`/cart/details`)
      .then((response: IObject) => {
        commit('SAVE_DETAILS', response.data.details);
        return response.data;
      })
  },
  CHANGE_COMPANY_NAME ({ commit }: IObject, payload: string): Promise<ICart> {
    return $HttpClient.post<ICart>(`/cart/updateName`, {
      body: payload
    })
      .catch(e => {
        return e.message
      })
      .then((response: IObject) => {
        EX_$Observer.context.$notification({
          text: '',
        })

        return response.success
      })
  },
  UPDATE_STREAMERS ({ commit }: IObject, payload: Array<IStreamerCart>): void {
    commit('SAVE_STREAMERS', payload);
  },
  DOWNLOAD_CART_SHEET ({ commit }: IObject, payload: Array<IStreamerCart>): Promise<void> {
    return $HttpClient.get(`/cart/downloadCartSheet`, {}, { responseType: 'blob' })
        .then((response: any): void => {
          const file_name = `StreamHunt медиаплан от ${moment().format('YYYY-MM-DD HH-mm-ss').toString()}.xlsx`;
          const link: HTMLAnchorElement = document.createElement('a');
          link.href = URL.createObjectURL(response);
          link.download = file_name;
          link.click();

          URL.revokeObjectURL(link.href)
        })
  },
};

const getters = {
  STREAMERS (state: ICart): Array<IStreamerCart> {
    return state._streamers;
  },
  CART_TOTAL (state: ICart): ITotalCart {
    return state._total;
  },
  MELEE_CART (state: ICart): Array<IPostCartService> {
    return state._meleeCart.services;
  },
  CAMPAIGN_NAME (state: ICart): string {
    return state._campaign_name;
  },
  DETAILS (state: ICart): IDetails {
    return state._details;
  }
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true
};
