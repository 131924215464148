import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_text = _resolveComponent("app-text")!
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass(["app-badge", _ctx.getBadgeClass])
  }, [
    _createVNode(_component_app_text, {
      text: _ctx.preText,
      tag: "p"
    }, null, 8, ["text"]),
    _createVNode(_component_app_text, {
      text: _ctx.text,
      "is-fetch": _ctx.isFetch,
      tag: "p",
      class: _normalizeClass(_ctx.isBlurred ? 'hidden' : '')
    }, null, 8, ["text", "is-fetch", "class"]),
    _createVNode(_component_app_text, {
      text: _ctx.postText,
      tag: "p"
    }, null, 8, ["text"]),
    (_ctx.iconName)
      ? (_openBlock(), _createBlock(_component_app_icon, {
          key: 0,
          name: _ctx.iconName,
          size: _ctx.iconSize
        }, null, 8, ["name", "size"]))
      : _createCommentVNode("", true)
  ], 2))
}