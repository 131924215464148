export enum iconPositionEnum {
  'left',
  'right'
}

export enum sizeEnum {
  'small',
  'standard',
  'large'
}

export enum colorEnum {
  'default',
  'blue'
}

export type TSize = keyof typeof sizeEnum

export type TIconPosition = keyof typeof iconPositionEnum

export type TColor = keyof typeof colorEnum
