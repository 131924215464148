import { buttonTypesEnum } from '@/ui/app-button/AppButton.types'

export enum targetEnum {
  '_self',
  '_blank',
  '_parent',
  '_top'
}

export enum relAnnotationEnum {
  'external',
  'nofollow',
  'noopener',
  'noreferrer',
  'opener'
}

export enum routerTypeEnum {
  'no-style',
  'main'
}

export enum routeParamsEnum {
  'name',
  'path',
  'params',
  'query',
  'hash',
  'append',
  'replace'
}

export const routeType = { ...buttonTypesEnum, ...routerTypeEnum }

export type TTarget = keyof typeof targetEnum
export type TRelAnnotation = keyof typeof relAnnotationEnum
export type TRouterTypeEnum = keyof typeof buttonTypesEnum | keyof typeof routerTypeEnum
export type TRouteParams = keyof typeof routeParamsEnum
