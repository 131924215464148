<template>
  <div class="footer">
    <div class="container">
      <div class="info">
        <app-text text="Связаться с нами" font-weight="700" />
        <a class="telegram-link" href="https://t.me/streamhunttv" target="_blank">
          <img
            class="telegram-link__logo"
            src="@/assets/image/icons/telegram_square.svg"
            alt="telegram"
          >
          <app-text text="@streamhunttv" />
        </a>
        <a class="info__link" href="mailto:team@streamhunt.tv">
          <app-text text="team@streamhunt.tv" />
        </a>
      </div>
      <div class="copyright">
<!--        <app-text text="© 2023, Стримхант" />-->
        <app-text :text="`© `+(new Date().getFullYear())+`, Стримхант`" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import AppText from "@/ui/app-text/AppText.vue";

export default defineComponent({
  name: "MainFooter",
  components: { AppText },
});
</script>

<style lang="scss" scoped>
@use "style.scss";
</style>
