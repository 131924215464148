export enum tagTextEnum {
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'span',
  'p',
  'bdi'
}

export enum statusTextEnum {
  'default',
  'success',
  'error',
  'secondary',
  'dark',
  'warning',
  'white',
  'gray',
  'purple'
}

export type TTagText = keyof typeof tagTextEnum
export type TStatusText = keyof typeof statusTextEnum
