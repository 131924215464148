
import { defineComponent, PropType } from 'vue';
import { isValidStringProp } from '@/utils/global/validateProp';
import {
  badgeColorsEnum,
  badgeSizesEnum,
  badgeTypesEnum,
  TBadgeColors,
  TBadgeSizes,
  TBadgeTypes
} from '@/ui/app-badge/AppBadge.types';
import { TIconName } from '@/ui/app-icon/AppIcon.types';

import AppText from '@/ui/app-text/AppText.vue';
import AppIcon from '@/ui/app-icon/AppIcon.vue';
import IObject from '@/types/IObject';
import { iconPositionEnum, TIconPosition } from '@/ui/global/helper';

export default defineComponent({
  name: 'AppBadge',
  components: {
    AppText,
    AppIcon
  },
  props: {
    type: {
      type: String as PropType<TBadgeTypes>,
      default: 'transparent',
      validator: (value: string): boolean => {
        return isValidStringProp(value, badgeTypesEnum);
      }
    },
    size: {
      type: String as PropType<TBadgeSizes>,
      default: 'default',
      validator: (value: string): boolean => {
        return isValidStringProp(value, badgeSizesEnum);
      }
    },
    color: {
      type: String as PropType<TBadgeColors>,
      default: 'blue',
      validator: (value: string): boolean => {
        return isValidStringProp(value, badgeColorsEnum);
      }
    },
    text: {
      type: String
    },
    iconName: {
      type: String as PropType<TIconName>
    },
    iconPosition: {
      type: String as PropType<TIconPosition>,
      default: 'right',
      validator: (value: string): boolean => {
        return isValidStringProp(value, iconPositionEnum);
      }
    },
    iconSize: {
      type: String,
      default: '6'
    },
    isFetch: {
      type: Boolean,
      default: false
    },
    isBlurred: {
      type: Boolean,
      default: false
    },
    preText: {
      type: String,
      default: ''
    },
    postText: {
      type: String,
      default: ''
    }
  },
  computed: {
    getBadgeClass (): IObject {
      return {
        [`app-badge__${this.iconPosition}`]: true,
        [`app-badge__${this.type}`]: true,
        [`app-badge__${this.size}`]: true,
        [`app-badge__${this.color}`]: true
      };
    },
    getTextStyle (): IObject {
      switch (this.size) {
        case 'large': {
          return {
            size: 14,
            line: 20
          };
        }
        default: {
          return {
            size: 12,
            line: 20
          };
        }
      }
    }
  }
});
