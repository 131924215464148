export enum buttonTypesEnum {
  'primary',
  'secondary',
  'white',
  'transparent',
  'success',
  'error',
  'orange',
  'icon-button',
  'border-primary',
  'border-success',
  'border-orange',
  'blue-light',
  'grey'
}

export type TButtonType = keyof typeof buttonTypesEnum
