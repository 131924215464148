
import { defineComponent } from 'vue';
import AppText from '@/ui/app-text/AppText.vue';
import $isWindow from '@/utils/mixins/isWindow.mixin';
import VerificationIcon from "@/components/ui/icons/VerificationIcon.vue";

export default defineComponent({
  name: 'VerifyBadge',
  components: {
    AppText,
    VerificationIcon
  },
  mixins: [$isWindow],
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
  }
});
