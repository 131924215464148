import { createRouter, createWebHistory, RouteComponent, RouteRecordRaw } from 'vue-router';
import store from '../store/index';
import EX_$Observer from '@/classes/base/observer';
import Cookies from "js-cookie";

const ShowCase = (): Promise<RouteComponent> => import('@/views/ShowcasePage/Showcase.vue');
const Authorization = (): Promise<RouteComponent> => import('@/views/AuthorizationPage/AuthorizationPage.vue');
const Registration = (): Promise<RouteComponent> => import('@/views/Registration/Registration.vue');
const ResetPassword = (): Promise<RouteComponent> => import('@/views/ResetPassword/ResetPassword.vue');
const Streamer = (): Promise<RouteComponent> => import('@/views/Streamer/Streamer.vue');
const Cart = (): Promise<RouteComponent> => import('@/views/Cart/Cart.vue');

const Account = (): Promise<RouteComponent> => import('@/views/Account/Account.vue');
const AccountInfo = (): Promise<RouteComponent> => import('@/views/Account/Info/InfoForm.vue');
const AccountCompany = (): Promise<RouteComponent> => import('@/views/Account/Company/CompanyForm.vue');
const AccountRequisites = (): Promise<RouteComponent> => import('@/views/Account/Requisites/RequisitesForm.vue');

const OrderSheet = (): Promise<RouteComponent> => import('@/views/OrderSheet/OrderSheet.vue');
const BriefPage = (): Promise<RouteComponent> => import('@/views/BriefPage/BriefPage.vue');
const RequestBriefPage = (): Promise<RouteComponent> => import('@/views/RequestBrief/RequestBriefPage.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'ShowCase',
    component: ShowCase,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/auth',
    name: 'Authorization',
    component: Authorization,
    meta: {
      layout: 'layout-authorization'
    }
  },
  {
    path: '/reg',
    name: 'Registration',
    component: Registration,
    meta: {
      layout: 'layout-authorization'
    }
  },
  {
    path: '/reset',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      layout: 'layout-authorization'
    }
  },
  {
    path: '/streamer/:id',
    name: 'Streamer',
    component: Streamer,
    meta: {
      requiresAuth: true,
      name: 'Вернуться на витрину стримеров'
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta: {
      requiresAuth: true,
      name: 'Вернуться на витрину стримеров'
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    redirect: '/account/info',
    meta: {
      requiresAuth: true,
      name: 'Вернуться на витрину стримеров'
    },
    children: [
      {
        path: '/account/info',
        name: 'AccountInfo',
        component: AccountInfo
      },
      {
        path: '/account/company',
        name: 'AccountCompany',
        component: AccountCompany
      },
      {
        path: '/account/requisites',
        name: 'AccountRequisites',
        component: AccountRequisites
      }
    ]
  },
  {
    path: '/order-sheet',
    name: 'OrderSheet',
    component: OrderSheet,
    meta: {
      requiresAuth: true,
      requiresVerify: true,
      name: 'Вернуться на витрину стримеров'
    }
  },
  {
    path: '/briefing/:id',
    name: 'BriefPage',
    component: BriefPage,
    meta: {
      requiresAuth: true,
      name: 'Вернуться на страницу заказов',
      url: '/order-sheet'
    }
  },
  {
    path: '/new_brief',
    name: 'RequestBriefPage',
    component: RequestBriefPage,
    meta: {
      requiresAuth: true,
      name: 'Вернуться на витрину стримеров'
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to) => {
  const isAuth = store.getters['auth/IS_AUTH'];

  // Save all utm label to cookies
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('utm_source')) {
    Cookies.set('streamhunt_utm_source', urlParams.get('utm_source'), { expires: 30, domain: process.env.VUE_APP_COOKIE_DOMAIN });
  }
  if (urlParams.get('utm_medium')) {
    Cookies.set('streamhunt_utm_medium', urlParams.get('utm_medium'), { expires: 30, domain: process.env.VUE_APP_COOKIE_DOMAIN });
  }
  if (urlParams.get('utm_campaign')) {
    Cookies.set('streamhunt_utm_campaign', urlParams.get('utm_campaign'), { expires: 30, domain: process.env.VUE_APP_COOKIE_DOMAIN });
  }
  if (urlParams.get('utm_content')) {
    Cookies.set('streamhunt_utm_content', urlParams.get('utm_content'), { expires: 30, domain: process.env.VUE_APP_COOKIE_DOMAIN });
  }
  if (urlParams.get('utm_term')) {
    Cookies.set('streamhunt_utm_term', urlParams.get('utm_term'), { expires: 30, domain: process.env.VUE_APP_COOKIE_DOMAIN });
  }

  if (to.meta.requiresAuth && !isAuth) {
    return {
      path: '/auth'
    };
  }

  if (!to.path.includes('streamer') && isAuth) {
    store.dispatch('cart/GET_CART').then(() => {
      EX_$Observer.broadcast('cartUpdated')
    })
  }
});

export default router;
