
import { defineComponent, PropType } from 'vue';
import {
  iconNamePath, iconNamePath3, iconNamePath4, TIconName,
} from '@/ui/app-icon/AppIcon.types';

export default defineComponent({
  name: 'AppIcon',
  props: {
    name: {
      type: String as PropType<TIconName>,
      required: true,
    },
    size: {
      type: [ String, Number ],
      default: '24',
    },
  },
  computed: {
    iconStyle () {
      return {
        '--fontSize': `${this.size}px`,
      };
    },
    path (): boolean {
      return Object.keys(iconNamePath).includes(this.name);
    },
    path3 (): boolean {
      return Object.keys(iconNamePath3).includes(this.name);
    },
    path4 (): boolean {
      return Object.keys(iconNamePath4).includes(this.name);
    },
  },
});
