import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39157e90"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "app-router__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), {
    to: _ctx.to,
    href: _ctx.href,
    target: _ctx.target,
    class: _normalizeClass([`app-router__${_ctx.type}`, "app-router"])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      (_ctx.text)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.text), 1))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["to", "href", "target", "class"]))
}