import { defineComponent } from 'vue'

interface IIsWindow {
  windowWidth: number
  windowHeight: number
}

interface IWindowSize {
  small: boolean
  mobile: boolean
  tablet: boolean
  tabletAndMobile: boolean
  desktop: boolean
}

export default defineComponent({
  data (): IIsWindow {
    return {
      windowWidth: 0,
      windowHeight: 0
    }
  },
  methods: {
    handleResize (): void {
      this.windowWidth = document.documentElement.clientWidth
      this.windowHeight = document.documentElement.clientHeight
    }
  },
  computed: {
    $isWindow (): IWindowSize {
      return {
        small: this.windowWidth < 576,
        mobile: this.windowWidth < 768,
        tablet: this.windowWidth >= 768 && this.windowWidth < 1101,
        tabletAndMobile: this.windowWidth < 992,
        desktop: this.windowWidth >= 1101
      }
    }
  },
  created (): void {
    this.windowWidth = window.document.documentElement.clientWidth
    this.windowHeight = window.document.documentElement.clientHeight
    window.addEventListener('resize', this.handleResize)
  },
  beforeUnmount (): void {
    window.removeEventListener('resize', this.handleResize)
  }
})
