import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d38e3d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "verify-badge-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_text = _resolveComponent("app-text")!
  const _component_verification_icon = _resolveComponent("verification-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$isWindow.desktop)
      ? (_openBlock(), _createBlock(_component_app_text, {
          key: 0,
          text: "Верификация в прогрессе"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_verification_icon, { class: "verify-icon" })
  ]))
}