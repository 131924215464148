import { IOrder, IOrders } from '@/store/modules/orders/orders.types';
import IObject from '@/types/IObject';

const state = (): IOrders => ({
  _orders: [] as Array<IOrder>,
  _order: {
    manual: false,
    charge: 100,
    mention: 6,
    sponsor: 4,
    product: 1,
    days: 30,
    enableSponsor: false,
    adStartDate: '',
    adEndDate: '',
  }
});

const mutations = {
  SAVE_MENTION (state: IOrders, payload: number ): void {
    const max = Math.floor(2 * state._order.days / 30 * 6);

    state._order.mention = payload;

    if (state._order.mention < 0) {
      state._order.mention = 0
    }

    if (state._order.mention > max) {
      state._order.mention = max;
    }
  },
  SAVE_SPONSOR (state: IOrders, payload: number): void {
    const max = Math.floor(2 * state._order.days / 30 * 4);

    state._order.sponsor = payload;

    if (state._order.sponsor < 0) {
      state._order.sponsor = 0
    }

    if (state._order.sponsor > max) {
      state._order.sponsor = max;
    }
  },
  SAVE_PRODUCT (state: IOrders, payload: number): void {
    const max = 2 * Math.ceil(state._order.days / 30);

    state._order.product = payload;

    if (state._order.product < 0) {
      state._order.product = 0
    }

    if (state._order.product > max) {
      state._order.product = max;
    }
  },
  SAVE_IS_ENABLE_STREAM (state: IOrders, payload: boolean): void {
    state._order.enableSponsor = payload;
  },
  SAVE_DAYS (state: IOrders, payload: number): void {
    state._order.days = payload;
  }
};

const actions = {
  SET_MENTION ({ commit }: IObject, payload: number | boolean): void {
    commit('SAVE_MENTION', payload);
  },
  SET_SPONSOR ({ commit }: IObject, payload: number | boolean): void {
    commit('SAVE_SPONSOR', payload);
  },
  SET_PRODUCT ({ commit }: IObject, payload: number | boolean): void {
    commit('SAVE_PRODUCT', payload);
  },
  SET_IS_ENABLED_SPONSOR ({ commit }: IObject, payload: boolean): void {
    commit('SAVE_IS_ENABLE_STREAM', payload);
  },
  SET_DAYS ({ commit }: IObject, payload: IObject): void {
    commit('SAVE_DAYS', payload);
  }
};

const getters = {
  ORDER (state: IOrders): IOrder {
    return state._order;
  }
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true
};
