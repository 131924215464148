
import { defineComponent, PropType } from 'vue';

import { iconPositionEnum, sizeEnum, TIconPosition, TSize } from '@/ui/global/helper';
import { isValidStringProp } from '@/utils/global/validateProp';
import { TSpinnerType } from '@/ui/app-spinner/AppSpinner.types';
import { buttonTypesEnum, TButtonType } from '@/ui/app-button/AppButton.types';

import AppIcon from '@/ui/app-icon/AppIcon.vue';
import { TIconName } from '@/ui/app-icon/AppIcon.types';

export default defineComponent({
  name: 'AppButton',
  components: {
    AppIcon
  },
  props: {
    text: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String as PropType<TButtonType>,
      default: 'primary',
      validator: (value: TButtonType): boolean => isValidStringProp(value, buttonTypesEnum)
    },
    size: {
      type: String as PropType<TSize>,
      default: 'standard',
      validator: (value: TSize): boolean => isValidStringProp(value, sizeEnum)
    },
    spinnerType: {
      type: String as PropType<TSpinnerType>,
      default: 'default'
    },
    loading: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String as PropType<TIconName>
    },
    iconSize: {
      type: [ String, Number ],
      default: 5
    },
    iconPosition: {
      type: String,
      default: 'right',
      validator: (value: TIconPosition): boolean => isValidStringProp(value, iconPositionEnum)
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonClass (): Record<string, boolean> {
      return {
        disabled: this.disabled,
        [`app-button__${this.type}`]: true,
        [`app-button__${this.size}`]: true,
        [`app-button__icon-${this.iconPosition}`]: !!this.iconPosition
      };
    }
  }
});
