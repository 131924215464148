
import { defineComponent, PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import * as H from '@/ui/app-router/AppRouter.types'
import { isValidStringProp, checkField } from '@/utils/global/validateProp'

export default defineComponent({
  name: 'AppRouter',
  props: {
    to: {
      type: [ String, Object ] as PropType<RouteLocationRaw>,
      validator: ( value: RouteLocationRaw ): boolean => {
        return typeof value === 'string'
          ? /^\/|#/.test(value)
          : checkField(value, H.routeParamsEnum)
      }
    },
    href: {
      type: String
    },
    target: {
      type: String as PropType<H.TTarget>,
      default: '_self',
      validator: ( value: H.TTarget ): boolean => {
        return isValidStringProp(value, H.targetEnum)
      }
    },
    rel: {
      type: String as PropType<H.TRelAnnotation>,
      validator: ( value: H.TRelAnnotation ): boolean => {
        return isValidStringProp(value, H.relAnnotationEnum)
      }
    },
    text: {
      type: [ String, Number ],
      default: ''
    },
    type: {
      type: String as PropType<H.TRouterTypeEnum>,
      default: 'no-style',
      validator: ( value: H.TRouterTypeEnum ): boolean => {
        return isValidStringProp(value, H.routeType)
      }
    }
  },
  computed: {
    currentComponent (): string {
      return this.to
        ? 'router-link'
        : 'a'
    }
  }
})
