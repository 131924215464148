
import { defineComponent } from 'vue';
import AppButton from '@/ui/app-button/AppButton.vue';
import { mapActions, mapGetters } from 'vuex';
import { IIntegration, IStreamerCart } from '@/store/modules/cart/cart.types';
import AppText from '@/ui/app-text/AppText.vue';
import AppBadge from '@/ui/app-badge/AppBadge.vue';
import { declOfNum } from '@/utils/global/declOfNum';
import { numberSeparator } from '@/utils/global/numberSeparator';
import $isWindow from '@/utils/mixins/isWindow.mixin';
import AppRouter from '@/ui/app-router/AppRouter.vue';
import { formatNumberToEuropean } from "@/utils/global/formatNumberToEuropean";
import AppModal from "@/ui/app-modal/AppModal.vue";
import * as moment from "moment/moment";

export default defineComponent({
  name: 'MiniCart',
  components: {
    AppModal,
    AppRouter,
    AppBadge,
    AppText,
    AppButton
  },
  mixins: [$isWindow],
  props: {
    isShow: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data () {
    return {
      isCartCleanPopup: false,
      isShowMiniCart: false,
      countMiniCart: 0
    }
  },
  methods: {
    ...mapActions('cart', [
      'EMPTY_CART',
      'GET_CART'
    ]),
    toggleMiniCart () {
      this.isShowMiniCart = !this.isShowMiniCart;

      if (!this.isShowMiniCart) {
        this.countMiniCart = 0
      }
    },
    getServices (item: IStreamerCart): string {
      const quantity = Object.values(item.services).reduce((acc: number, item) => acc + (item.quantity > 0 ? 1 : 0), 0);
      return `${quantity} ${declOfNum(quantity, [ 'услуга', 'услуги', 'услуг' ])}`;
    },
    getAdvertisingCampaignDateRange (item: IStreamerCart): string {
      const format = "DD.MM.YYYY";
      const start_date: moment.Moment = moment(new Date(item.started_at));

      return `${start_date.format(format)} - ${start_date.add(item.services.branding.quantity - 1 ?? 0, 'days').format(format)}`;
    },
    getPrice (item: IIntegration): string {
      return `${numberSeparator(item.quantity * item.price)} ₽`;
    },
    close (): void {
      if (this.isShowMiniCart) {
        this.isShowMiniCart = false
        this.countMiniCart = 0
      }
    },
    getText (serviceId: number): string {
      switch (serviceId) {
        case 1:
          return 'Брендированный контент';
        case 2:
          return 'Голосовые упоминания';
        case 3:
          return 'Продуктовые интеграции';
        default:
          return 'Креативные спонсорские интеграции';
      }
    },
    emptyCart (): void {
      this.isCartCleanPopup = false;
      this.EMPTY_CART()
        .then(() => {
          this.GET_CART();
        });
    },
    closeCartCleanPopup (): void {
      this.isCartCleanPopup = false;
      this.$emit('close-cart-clean-popup');
    },
    openCartCleanPopup (): void {
      this.isCartCleanPopup = true
    },
    goToShowcase (): void {
      this.$router.push('/');
      this.countMiniCart = 0
      this.close()
    },
    clickOutside (): void {
      if (this.isShowMiniCart) {
        this.countMiniCart++;
        if (this.countMiniCart % 2 === 0) this.close()
      }
    }
  },
  computed: {
    ...mapGetters('cart', [
      'STREAMERS',
      'CART_TOTAL',
      'DETAILS'
    ]),
    getCartText (): string {
      return this.STREAMERS.length > 0 && this.$isWindow.desktop
        ? `${this.STREAMERS.length} ${declOfNum(this.STREAMERS.length, [ 'стример', 'стримера', 'стримеров' ])} | ${numberSeparator(this.CART_TOTAL.price)}₽`
        : '';
    },
    getStreamers (): IStreamerCart[] {
      return this.STREAMERS;
    },
    getTotalCost (): string {
      return `${numberSeparator(this.CART_TOTAL.price)} ₽`;
    },
    getTotalServices (): string {
      const total = this.getStreamers.reduce((acc: number, item) => acc + (Object.values(item.services).reduce((acc: number, item) => acc + (item.quantity > 0 ? 1 : 0), 0)), 0)
      return `${total} ${declOfNum(total, [ 'услуга', 'услуги', 'услуг' ])}`;
    },
    getCPV (): string {
      const cpv = (this.DETAILS.total / this.sumViews).toFixed(4)
      return `CPV | ${formatNumberToEuropean(cpv)} ₽`;
    },
    getViews (): string {
      return `~ ${numberSeparator(this.CART_TOTAL.views)}`
    },
    getUniqueViewers (): string {
      return `~ ${numberSeparator(this.CART_TOTAL.unique_viewers)}`;
    },
    sumViews (): number {
      return this.STREAMERS.reduce((accumulator: number, streamer: IStreamerCart) => accumulator + Math.floor(streamer.views_90 / 90 * streamer.services.branding.quantity), 0)
    }
  }
});
