import IObject from '@/types/IObject';
/**
 * @param prop                 - Входной параметр, который надо валидировать
 * @param enumObj              - Объект или enum допустимых значений для <b>prop</b>
 * @description                - Функция валидирует строчные входные параметры<br/>
 *                               <b>Прим.</b> Строка (prop) может состоять из нескольких допустимых слов,
 *                               для этого она разбивается на массив и проверяется каждое слово
 */
export function isValidStringProp (prop: string, enumObj: IObject): boolean {
  const propArray: Array<string> = prop.split(' ');
  const validValues: Array<string> = Object.keys(enumObj).filter((type) => Number.isNaN(+type));
  return propArray.length > 1
    ? propArray.map((item) => validValues.some((v) => v === item)).every((item) => item)
    : validValues.includes(prop);
}
/**
 * @param value                - Входной объект, поля которого надо валидировать
 * @param enumObj              - Объект или enum, полей которые могут присутствовать <b>value</b>
 * @description                - Функция проверяет объект на наличие допустимых полей, если в объекте есть поля,
 *                               которые не ожидаются, вернет false
 */
export function checkField (value: IObject, enumObj: IObject): boolean {
  const objFields: Array<string> = Object.keys(value);
  const chekFields: Array<string> = Object.keys(enumObj).filter((type) => Number.isNaN(+type));
  const validField: Array<string> = objFields.filter((item) => chekFields.some((v) => v === item));

  return validField.length === objFields.length;
}
/**
 * @param value                - Входной объект, поля которого надо валидировать
 * @param requiredFields       - Массив обязательных полей, которые должны присутствовать <b>value</b>
 * @description                - Функция проверяет объект на наличие обязательных полей
 */
export function checkRequireField (value: IObject, requiredFields: Array<string>): boolean {
  for (const key of requiredFields) {
    const isInclude = Object.prototype.hasOwnProperty.call(value, key);
    if (!isInclude) return false;
  }
  return true;
}
