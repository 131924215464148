import IObject from '@/types/IObject';
import {
  IGetCartServices,
  ITotalCart
} from '@/store/modules/cart/cart.types';

export class StreamerCartModel {
  public streamer_id = 0;
  public display_name = '';
  public avatar_url = '';
  public services = {} as IGetCartServices;
  public total = {} as ITotalCart;
  public isOpen = false;
  public isManual = false;
  public views_90 = 0;
  public minimal_campaign_price = 0;
  public cpv = ''
  public total_price = 0
  public views_forecast = 0
  public unique_viewers_forecast = 0
  public unique_viewers_30 = 0
  public started_at = ''

  constructor (obj: IObject) {
    Object.keys(this)
      .forEach((key) => {
        const has = Object.prototype.hasOwnProperty.call(obj, key);
        // @ts-ignore
        this[key] = has ? obj[key] : this[key];
      });
  }
}
