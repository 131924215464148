import { DirectiveBinding, ObjectDirective } from 'vue'

interface ClickOutsideElement extends HTMLElement {
  clickOutsideEvent ( e: Event ): void
}

const beforeMount = ( el: ClickOutsideElement, binding: DirectiveBinding ): void => {
  el.clickOutsideEvent = ( event: Event ): void => {
    if (!(el === event.target || el.contains(event.target as Node | null))) {
      binding.value(event, el)
    }
  }
  document.body.addEventListener('click', el.clickOutsideEvent)
}

const unmounted = (el: ClickOutsideElement): void => {
  document.body.removeEventListener('click', el.clickOutsideEvent)
}

const clickOutside: ObjectDirective = {
  beforeMount,
  unmounted
}

export default clickOutside
