import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_text = _resolveComponent("app-text")!
  const _component_app_router = _resolveComponent("app-router")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["t-container", _ctx.type])
  }, [
    _createVNode(_component_app_icon, {
      name: _ctx.getIcon,
      class: "icon"
    }, null, 8, ["name"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_app_text, {
        text: _ctx.getTitle,
        class: "title",
        "font-size": "17",
        "font-weight": "700",
        "line-height": "24"
      }, null, 8, ["text"]),
      (_ctx.href)
        ? (_openBlock(), _createBlock(_component_app_router, {
            key: 0,
            href: _ctx.href,
            target: "_blank",
            text: _ctx.text,
            type: "breeze"
          }, null, 8, ["href", "text"]))
        : (_openBlock(), _createBlock(_component_app_text, {
            key: 1,
            text: _ctx.text,
            class: "text",
            "line-height": "24",
            status: "gray"
          }, null, 8, ["text"]))
    ])
  ], 2))
}