export enum iconNameNoPath {
  'icon-arrow-down',
  'icon-arrow-left',
  'icon-arrow-right',
  'icon-cross',
  'icon-filter',
  'icon-people',
  'icon-search',
  'icon-streamer',
  'icon-success',
  'icon-eye',
  'icon-clock',
  'icon-cart',
  'icon-eye-tube',
  'icon-group',
  'icon-info',
  'icon-man',
  'icon-woman',
  'icon-minus',
  'icon-plus',
  'icon-levin',
  'icon-profile',
  'icon-pan',
  'icon-alert',
  'icon-diplomat',
  'icon-card',
  'icon-people-star',
  'icon-eye-open',
  'icon-eye-close',
  'icon-man-2',
  'icon-calendar',
  'icon-folder'
}

export enum iconNamePath {
  'icon-copy',
  'icon-cart',
}

export enum iconNamePath3 {
  'icon-eye-close',
  'icon-cart',
}

export enum iconNamePath4 {
  'icon-eye-close',
  'icon-cart',
}

export const iconName = {
  ...iconNameNoPath, ...iconNamePath, ...iconNamePath3, ...iconNamePath4
};
export type TIconName = keyof typeof iconNameNoPath | keyof typeof iconNamePath |
  keyof typeof iconNamePath3 | keyof typeof iconNamePath4
