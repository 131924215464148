import IObject from '@/types/IObject';
import { ComponentCustomProperties } from 'vue'
import { Callback } from '@/types/callback';

interface ISubscriber {
  name: string
  fn ( data?: IObject ): void
}

interface IObserver {
  context: ComponentCustomProperties
  broadcast <Payload>( name: string, data?: Payload ): void
  subscribe <Payload>( name: string, fn: Callback<Payload> ): void
  unsubscribe ( name: string ): void
}

export class $Observer implements IObserver {
  private subscribers: Array<ISubscriber> = []
  private _context!: ComponentCustomProperties

  get context (): ComponentCustomProperties {
    return this._context
  }

  set context ( context: ComponentCustomProperties ) {
    this._context = context
  }

  public broadcast <Payload> ( name: string, data?: Payload ): void {
    this.subscribers.forEach((subscriber: ISubscriber) => {
      if (subscriber.name === name) {
        subscriber.fn(data)
      }
    })
  }

  public subscribe <Payload> (name: string, fn: Callback<Payload> ): void {
    this.subscribers.push({
      name,
      fn
    })
  }

  public unsubscribe ( name: string ): void {
    this.subscribers = this.subscribers.filter((subscriber: ISubscriber) => subscriber.name !== name)
  }
}

export default new $Observer()
