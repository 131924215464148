import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import clickOutside from '@/utils/directives/clickOutside';
import cleave from '@/utils/directives/cleave';
import Toast, { PluginOptions, POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import notification from '@/utils/global/notification';
import { Skeletor } from 'vue-skeletor'

import LayoutDefault from '@/layouts/default/LayoutDefault.vue';
import LayoutAuthorization from '@/layouts/authorization/LayoutAuthorization.vue';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import '@/assets/style/skeletor.scss'

import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

const options: PluginOptions = {
  icon: false,
  containerClassName: 'app-toast',
  hideProgressBar: true,
  closeButton: false,
  timeout: 3000,
  position: POSITION.BOTTOM_RIGHT
};

const app = createApp(App)
  .use(store)
  .use(router)
  .use(Toast, options)
  .use(notification)
  .use(VCalendar, {})

app.component('DefaultLayout', LayoutDefault);
app.component('AuthLayout', LayoutAuthorization);
app.component('VueSlider', VueSlider);
app.component(Skeletor.name, Skeletor)

app.directive('click-outside', clickOutside);
app.directive('cleave', cleave);

app.mount('#app');
