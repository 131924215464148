
import { defineComponent } from 'vue';
import LayoutDefault from '@/layouts/default/LayoutDefault.vue';
import LayoutAuthorization from '@/layouts/authorization/LayoutAuthorization.vue';
import EX_$Observer from '@/classes/base/observer'
import Cookies from 'js-cookie';
import $HttpClient from '@/utils/http-clients/http-client';

export default defineComponent({
  components: {
    LayoutDefault,
    LayoutAuthorization
  },
  computed: {
    layout () {
      return this.$route.meta.layout || 'layout-default'
    }
  },
  created () {
    EX_$Observer.context = this

    if (Cookies.get('authToken')) {
      $HttpClient.setAuthToken(Cookies.get('authToken') as string)
    }

    EX_$Observer.subscribe('logout', () => {
      Cookies.remove('authToken');
      this.$router.push('/auth')
    })
  }
});
