
import { defineComponent } from 'vue';
import AppText from '@/ui/app-text/AppText.vue';
import { mapGetters } from 'vuex';
import { IProfileInfo } from '@/store/modules/profile/profile.types';
import AppIcon from '@/ui/app-icon/AppIcon.vue';
import $isWindow from '@/utils/mixins/isWindow.mixin';
import Cookies from 'js-cookie';

export default defineComponent({
  name: 'ProfileMini',
  components: {
    AppIcon,
    AppText
  },
  mixins: [$isWindow],
  props: {
    name: {
      type: String
    }
  },
  data () {
    return {
      isShow: false,
      count: 0
    }
  },
  methods: {
    goToProfile (e): void {
      e.preventDefault()
      this.$router.push('/account/info')
      this.$emit('close')
      this.count = 0
    },
    goToOrderSheet (e): void {
      e.preventDefault()
      this.$router.push('/order-sheet')
      this.$emit('close')
      this.count = 0
    },
    toggleProfile (): void {
      this.isShow = !this.isShow;
      if (!this.isShow) {
        this.count = 0
      }
    },
    closeProfile (): void {
      this.isShow = false;
      this.count = 0;
    },
    logout (): void {
      if (Cookies.get('authToken')) {
        Cookies.remove('authToken');
        this.$router.push('/auth')
        this.count = 0
        this.closeProfile()
      }
    },
    clickOutside (): void {
      if (this.isShow) {
        this.count++;
        if (this.count % 2 === 0) this.closeProfile()
      }
    }
  },
  computed: {
    ...mapGetters('profile', [
      'INFO',
      'IS_VERIFIED'
    ]),
    getInfo (): IProfileInfo {
      return this.INFO
    },
    getName (): string {
      return this.getInfo.name
    }
  }
});
