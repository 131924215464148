import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import EX_$Observer, { $Observer } from '@/classes/base/observer';
import {
  IGetPayload,
  IHttpClient,
  IHttpConfig,
  IPostPayload,
  RejectedApi
} from '@/utils/http-clients/interfaces';
import IObject from "@/types/IObject";

// TODO: должен стать базовым
export class $HttpClient implements IHttpClient {
  protected _instance: AxiosInstance;
  protected _tokenName: string;
  protected _rejectedApi: RejectedApi[];

  protected EX_$Observer: $Observer;

  constructor (options: IHttpConfig) {
    this._instance = axios.create(options.config);
    this._tokenName = options.tokenName;
    this._rejectedApi = [];

    this.EX_$Observer = EX_$Observer;

    this.initializeResponseInterceptor();
  }

  public get<Response> (resource: string, payload?: IGetPayload, config_params?: object): Promise<Response> {
    this.setHeadersDispatch(payload?.name);

    const config = {
      ...{
        params: payload?.params
      },
      ...config_params
    }

    return this._instance.get(resource, config)
      .catch(e => {
        if (e.message && e.message.length > 0) {
          EX_$Observer.context.$notification({
            text: e.message,
            type: 'error'
          })
        }
        if (e.errors) {
          e.errors.forEach((item: string) => {
            EX_$Observer.context.$notification({
              text: item,
              type: 'error'
            })
          })
        }
        throw e
      })
      .then(value => {
        // @ts-ignore
        if (value.code === 10) this.EX_$Observer.broadcast('logout');

        // @ts-ignore
        return value as Response;
      });
  }

  public post<Response> (resource: string, payload?: IPostPayload): Promise<Response> {
    this.setHeadersDispatch(payload?.name);
    return this._instance.post(resource, payload?.body, { params: payload?.params })
      .catch(e => {
        if (e.message && e.message.length > 0) {
          EX_$Observer.context.$notification({
            text: e.message,
            type: 'error'
          })
        }
        if (e.errors) {
          e.errors.forEach((item: string) => {
            EX_$Observer.context.$notification({
              text: item,
              type: 'error'
            })
          })
        }
        throw e
      })
      .then(value => {
        // @ts-ignore
        if (value.code === 10) this.EX_$Observer.broadcast('logout');

        // @ts-ignore
        return value as Response;
      });
  }

  public postForm<Response> (resource: string, payload?: IObject): Promise<Response> {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: resource,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: payload
    }

    return this._instance.request(config)
      .catch((e) => {
        if (e.message && e.message.length > 0) {
          EX_$Observer.context.$notification({
            text: e.message,
            type: 'error'
          })
        }
        if (e.errors) {
          e.errors.forEach((item: string) => {
            EX_$Observer.context.$notification({
              text: item,
              type: 'error'
            })
          })
        }
        throw e
      })
      .then(value => {
        // @ts-ignore
        if (value.code === 10) this.EX_$Observer.broadcast('logout');

        // @ts-ignore
        return value as Response;
      });
  }

  public put<Response> (resource: string, payload?: IPostPayload): Promise<Response> {
    this.setHeadersDispatch(payload?.name);
    return this._instance.put(resource, payload?.body, { params: payload?.params });
  }

  public patch<Response> (resource: string, payload?: IPostPayload): Promise<Response> {
    this.setHeadersDispatch(payload?.name);
    return this._instance.patch(resource, payload?.body, { params: payload?.params });
  }

  public delete<Response> (resource: string, payload?: IGetPayload): Promise<Response> {
    this.setHeadersDispatch(payload?.name);
    return this._instance.delete(resource, { params: payload?.params });
  }

  public setAuthToken (token: string): void {
    this._instance.defaults.headers.common[this._tokenName] = `Bearer ${token}`;
  }

  private setHeadersDispatch (name?: string): void {
    if (name) this._instance.defaults.headers.common.dispatch = name;
  }

  private initializeResponseInterceptor (): void {
    this._instance.interceptors.response.use(
      this.handleResponse.bind(this),
      this.handleError.bind(this)
    );
  }

  private handleResponse ({ data }: AxiosResponse): Promise<AxiosResponse> {
    return new Promise(resolve => resolve(data));
  }

  private handleError (error: AxiosError): Promise<AxiosError> {
    return new Promise((resolve, reject) => {
      if (error.response?.status === 401) {
        if (error.response.config.headers?.dispatch === 'refresh') {
          reject(error.response?.data);
          return;
        }
        this._rejectedApi.push({
          url: error.response.config?.url ?? '',
          name: error.response.config.headers?.dispatch as string ?? '',
          params: error.response.config.params,
          body: error.response.config.method === 'post' || error.response.config.method === 'patch'
            ? error.response.config.data ? JSON.parse(error.response.config.data) : {}
            : undefined
        });

        setTimeout(() => {
          if (this._rejectedApi.length) {
            this.EX_$Observer.broadcast('need_refresh', this._rejectedApi);
            this._rejectedApi = [];
          }
        }, 500);
      }

      reject(error.response?.data);
    });
  }
}

export default new $HttpClient({
  config: {
    baseURL: process.env.VUE_APP_API_DOMAIN,
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'Accept': 'application/json'
    }
  },
  tokenName: 'Authorization'
});
