import Cleave from 'cleave.js'
import { DirectiveBinding, ObjectDirective } from 'vue'

const mounted = ( el: HTMLInputElement, binding: DirectiveBinding ): void => {
  if (!binding.value || !Object.keys(binding.value).length) return

  el.cleave = new Cleave(el, binding.value)
}

const updated = ( el: HTMLInputElement, binding: DirectiveBinding ): void => {
  if (el.cleave) {
    el.cleave.destroy()
    el.cleave = new Cleave(el, binding.value)
  }
}

const cleave: ObjectDirective = {
  mounted,
  updated
}

export default cleave
