<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22.5512 12.4978L24.1487 9.25037L21.2027 7.27073L21.0257 3.62761L17.5227 3.44561L15.6177 0.382812L12.4967 2.04681L9.37416 0.384892L7.47116 3.44925L3.96866 3.63333L3.79266 7.27697L0.847656 9.25817L2.44766 12.504L0.850656 15.7504L3.79666 17.73L3.97366 21.3731L7.47666 21.5551L9.38166 24.6185L12.5027 22.9545L15.6252 24.6159L17.5282 21.5515L21.0307 21.3674L21.2067 17.7243L24.1517 15.7431L22.5512 12.4978ZM11.1197 16.4326L7.70966 12.8867L8.77016 11.7838L11.1362 14.2439L16.2372 9.10217L17.2812 10.2223L11.1197 16.4326Z" fill="#046AFB" />
  </svg>
</template>

<script>
export default {
  name: "VerificationIcon",
};
</script>
