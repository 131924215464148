
import { defineComponent } from 'vue';
import AppIcon from '@/ui/app-icon/AppIcon.vue';
import AppText from '@/ui/app-text/AppText.vue';
import AppRouter from '@/ui/app-router/AppRouter.vue';

export default defineComponent({
  name: 'AppToast',
  components: {
    AppRouter,
    AppIcon,
    AppText
  },
  props: {
    type: {
      type: String,
      default: 'success'
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
    href: {
      type: String
    }
  },
  methods: {},
  computed: {
    getIcon () {
      return this.type === 'success'
        ? 'icon-success'
        : this.type === 'error'
          ? 'icon-alert'
          : '';
    },
    getTitle () {
      return this.title
        ? this.title
        : this.type === 'success'
        ? 'Успешно!'
          : this.type === 'error'
            ? 'Ошибка!'
            : ''
    }
  }
});
