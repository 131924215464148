export enum badgeTypesEnum {
  'transparent',
  'white',
  'solid'
}

export enum badgeSizesEnum {
  'large',
  'default',
  'mini',
  'extra-large',
  'mini-2'
}

export enum badgeColorsEnum {
  'blue',
  'purple',
  'red',
  'gray',
  'green',
  'black',
  'blue-dark',
  'blue-black'
}

export type TBadgeTypes = keyof typeof badgeTypesEnum
export type TBadgeSizes = keyof typeof badgeSizesEnum
export type TBadgeColors = keyof typeof badgeColorsEnum
