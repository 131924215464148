import { createStore } from 'vuex';
import searchShowcase from '@/store/modules/search-showcase';
import auth from '@/store/modules/auth';
import streamer from '@/store/modules/streamer';
import orders from '@/store/modules/orders';
import cart from '@/store/modules/cart';
import profile from '@/store/modules/profile'
import orderSheet from '@/store/modules/order-sheet'
import brief from '@/store/modules/brief'

export default createStore({
  modules: {
    searchShowcase,
    auth,
    streamer,
    orders,
    cart,
    profile,
    orderSheet,
    brief,
  }
});
