import IObject from '@/types/IObject';
import $HttpClient from '@/utils/http-clients/http-client';
import { IStreamer } from '@/types/IStreamer';
import { IStreamerModule } from '@/store/modules/streamer/streamer.types';
import EX_$Observer from '@/classes/base/observer';
import router from '@/router'

const state = (): IStreamerModule => ({
  streamer: {} as IStreamer
});

const mutations = {
  SAVE_STREAMER (state: IStreamerModule, payload: IStreamer): void {
    state.streamer = payload;
  }
};

const actions = {
  GET_STREAMER ({ commit }: IObject, id: number): Promise<IStreamer> {
    return $HttpClient.get<IStreamer>(`/streamer/${id}`)
      .catch(() => {
        router.push('/')
      })
      .then((response: IObject) => {
        commit('SAVE_STREAMER', response.data)
        return response.data
      })
  }
};

const getters = {
  STREAMER (state: IStreamerModule): IStreamer {
    return state.streamer;
  }
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true
};
