
import { defineComponent } from 'vue';
import MainHeader from '@/layouts/main-header/MainHeader.vue';
import MainFooter from "@/layouts/main-footer/MainFooter.vue";
import EX_$Observer from '@/classes/base/observer';

export default defineComponent({
  name: 'LayoutDefault',
  components: {
    MainHeader,
    MainFooter
  },
  data () {
    return {
      EX_$Observer: EX_$Observer,
    }
  }
});
