import IObject from '@/types/IObject'

export class ShowcaseCategoriesModel {
  public id = 0
  public name = ''
  public checked = false

  constructor (obj: IObject) {
    Object.keys(this)
      .forEach((key) => {
        const has = Object.prototype.hasOwnProperty.call(obj, key)
        // @ts-ignore
        this[key] = has ? obj[key] : this[key]
      })
  }
}
