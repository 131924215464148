import { IAuth, IAuthResponse } from '@/store/modules/auth/auth.types';
import $HttpClient from '@/utils/http-clients/http-client';
import IObject from '@/types/IObject';
import Cookies from 'js-cookie';
import EX_$Observer from '@/classes/base/observer';
import router from '@/router';

const state = (): IAuth => ({
  isAuth: !!Cookies.get('authToken')
});

const mutations = {
  SET_AUTH_STATE (state: IAuth, payload: boolean): void {
    state.isAuth = payload;
  }
};

const actions = {
  AUTHORIZATION ({ commit }: IObject, payload: IObject): Promise<boolean> {
    return $HttpClient.post<IAuthResponse>('/login', {
      body: payload
    })
      .then(response => {
        $HttpClient.setAuthToken(response.data.token);
        Cookies.set('authToken', response.data.token, { expires: 30 });
        commit('SET_AUTH_STATE', true);

        return true
      })
  },
  REGISTRATION ({ commit }: IObject, payload: FormData): Promise<boolean> {
    return $HttpClient.post('/signup', {
      body: payload
    })
      .then(response => {
        return true
      })
  },
  SEND_EMAIL_RESET ({ commit }: IObject, payload: FormData): Promise<boolean> {
    return $HttpClient.post('/reset_password', {
      body: payload
    })
      .then((response) => {
        return true
      })
  },
  SEND_NEW_PASSWORD ({ commit }: IObject, payload: FormData): Promise<unknown> {
    return $HttpClient.post('/set_new_password', {
      body: payload
    })
      .then(response => {
        return true
      })
  }
};

const getters = {
  IS_AUTH (state: IAuth): boolean {
    return state.isAuth;
  }
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
};
