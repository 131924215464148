import IObject from '@/types/IObject';
import $HttpClient from '@/utils/http-clients/http-client';
import { IBrief, IBriefModule } from '@/store/modules/brief/brief.types';
import { IProfileInfoResponse } from "@/store/modules/profile/profile.types";
import EX_$Observer from "@/classes/base/observer";

const state = (): IBriefModule => ({
  brief: {} as IBrief
});

const mutations = {
  SAVE_BRIEF (state: IBriefModule, payload: IBrief): void {
    state.brief.brand_name = payload.brand_name;
    state.brief.goal = payload.goal;
    state.brief.extra_intro = payload.extra_intro;
    state.brief.name_in_russian = payload.name_in_russian;
    state.brief.key_points = payload.key_points;
    state.brief.banned_words = payload.banned_words;
    state.brief.restrictions = payload.restrictions;
    state.brief.chat_bot_message = payload.chat_bot_message;
  },
};

const actions = {
  GET_BRIEF ({ commit }: IObject, campaign_id: number): Promise<IBrief> {
    return $HttpClient.get<IBrief>(`/brief/` + campaign_id.toString())
      .then((response: IObject) => {
        commit('SAVE_BRIEF', response.data);

        return response.data;
      })
  },
  SEND_REQUEST_BRIEF ({ commit }: IObject, payload: IObject): Promise<IBrief> {
    return $HttpClient.postForm<IProfileInfoResponse>(`/request_brief`, payload)
      .then((response: IObject) => {
        EX_$Observer.context.$notification({
          text: 'Заявка отправлена'
        })
        return response.data;
      });
  },
  UPDATE_BRIEF ({ commit }: IObject, payload: IObject): Promise<IBrief> {
    return $HttpClient.postForm<IProfileInfoResponse>(`/brief`, payload)
      .then((response: IObject) => {
        EX_$Observer.context.$notification({
          text: 'Данные изменены'
        })
        return response.data;
      });
  },
  DELETE_FILE ({ commit }: IObject, payload: IObject): Promise<IBrief> {
    return $HttpClient.post<IProfileInfoResponse>(`/brief/removeFile`, {
      body: payload
    })
    .then((response: IObject) => {
      return response.data;
    })
  }
};

const getters = {
  BRIEF (state: IBriefModule): IBrief {
    return state.brief;
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true
};
