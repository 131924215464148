
import { defineComponent } from 'vue';
import AppBadge from '@/ui/app-badge/AppBadge.vue';
import { mapActions, mapGetters } from 'vuex';
import $isWindow from '@/utils/mixins/isWindow.mixin';
import MiniCart from '@/components/cart/mini-cart/MiniCart.vue';
import ProfileMini from '@/components/account/profile-mini/ProfileMini.vue';
import AppRouter from '@/ui/app-router/AppRouter.vue';
import AppButton from "@/ui/app-button/AppButton.vue";
import VerifyBadge from "@/components/verification/verify-badge/VerifyBadge.vue";

export default defineComponent({
  name: 'MainHeader',
  components: {
    VerifyBadge,
    AppButton,
    AppRouter,
    AppBadge,
    MiniCart,
    ProfileMini
  },
  mixins: [$isWindow],
  data () {
    return {
      isShowMiniCart: false,
      isShowProfile: false
    };
  },
  methods: {
    ...mapActions('profile', [
      'GET_INFO',
    ]),
    goToNewBrief () {
      this.$router.push('/new_brief');
    },
    clickLogo () {
      this.$router.push('/');
    },
    goToUrl () {
      return this.$route.meta.url
        ? this.$router.push(`${this.$route.meta.url}`)
        : this.$router.push(sessionStorage.getItem('showCasePath') || '/');
    }
  },
  computed: {
    ...mapGetters('auth', [
      'IS_AUTH'
    ]),
    ...mapGetters('profile', [
      'INFO',
      'IS_VERIFIED'
    ]),
    getBadgeText (): string {
      return this.$route.meta.name ? this.$route.meta.name as string : '';
    },
    getProfileName (): string {
      return this.INFO.name || ''
    }
  },
  mounted () {
    if (this.IS_AUTH) this.GET_INFO()
  }
});
